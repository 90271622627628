import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import buttonRowPreviewData from '../../../../data/previews/button-row.yml';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const ButtonRow = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.ButtonRow;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Space"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Layout" tierThree="Space" />

      <PageNavigation links={['Scale', 'Style', 'Usage', 'Platform']} />

      <Section title="Scale">
        <Paragraph>
          Our scale is based on a 16px (1rem) unit. We chose this because it is
          our base typography size and a factor of common screen resolutions.
        </Paragraph>
        <Paragraph>
          Rather than name the scale with t-shirt sizing (small, medium, large),
          we opted for a mathematical approach, using fractions and multiples of
          our base size (16px). The result is easy to learn and extend, with a
          simple vocabulary to convey specific meaning.
        </Paragraph>
        <Paragraph>
          Our space sizes are ⅛ space (2px), ¼ space (4px), ½ space (8px), ¾
          space (12px), 1 space (16px), 1 ¼ spaces (18px), 1 ½ spaces (24px), 1
          ¾ spaces (28px), 2 spaces (32px), 3 spaces (48px) and 4 spaces (64px).
        </Paragraph>

        <ComponentPreview
          name="ButtonType"
          layout="split"
          previewData={buttonRowPreviewData.spaces}>
          <ButtonRow>
            <Button buttonType="subtle">Edit</Button>
            <Button buttonType="primary">Save</Button>
          </ButtonRow>
        </ComponentPreview>
      </Section>

      <Section title="Style">
        <SectionSubhead>Relative</SectionSubhead>
        <Paragraph>
          The amount of space needed to convey the appropriate relationship or
          separation depends on how space is used throughout the rest of the
          interface.
        </Paragraph>
        <Paragraph>
          In a dense interface, a half space might be perfect for associated
          elements. But in a sparse interface with a lot of negative space, that
          same half space may not be sufficient.
        </Paragraph>

        <SectionSubhead>Relationships</SectionSubhead>
        <Paragraph>
          A small amount of space between elements implies association or a
          close connection. Vice versa, a large amount of space implies
          separation. Read our{' '}
          <Link href="/guidelines/layout/division/design" isDesignCode>
            division guidelines
          </Link>{' '}
          for more.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Measuring Typography</SectionSubhead>
        <Paragraph>
          When measuring space for typographical elements, calculate size based
          on the line-height of the type in question.
        </Paragraph>
        <DontDo
          dontText="measure descender to ascender, baseline or x-height."
          doText="account for line-height when measuring."
          imgFilename="space-measure"
        />

        <SectionSubhead>Measuring Bounding Boxes</SectionSubhead>
        <Paragraph>
          When calculating space for a bounding box, measure from edge to edge.
          Boxes have two types of space: inside (padding) and outside (margin).
          Inside space does affect the size of the box, whereas outside space
          does not.
        </Paragraph>
        <Paragraph>
          Outside space is the distance between the box in question and other
          elements within the interface. Outside space needs to be accounted for
          in determining overall layout considerations, but is not a part of a
          given box’s size.
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
